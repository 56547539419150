<template>
	<div class="part-box">
		<div class="col cc user-info">
			<el-image :src="askUserInfo.avatar" class="avatar"></el-image>
			<div class="nickname">{{ askUserInfo.nickname }}</div>
			<div class="row auth" v-if="askUserInfo.is_cert == 1">
				<el-image fit="contain" :src="require('@/pc/assets/user/auth.png')" class="img"></el-image>
				<div class="status">{{ askUserInfo.major_name }}</div>
			</div>
			<div class="sub" v-if="isMyAsk">
				<el-button type="primary" round size="small" @click="navUserHome()">
					<i class="el-icon-s-home"></i>
					<span>个人主页</span>
				</el-button>
			</div>
			<div class="sub" v-else>
				<el-button type="primary" plain round size="small" @click.stop="tapSub()" v-if="askUserInfo.is_focus === '0'">
					<i class="el-icon-plus"></i>
					<span>关注</span>
				</el-button>
				<el-button type="primary" round size="small" @click.stop="tapSub()" v-else>
					<i class="el-icon-check"></i>
					<span>已关注</span>
				</el-button>
			</div>
		</div>
		<div class="row cc panel">
			<div class="col item cc">
				<span class="num">{{ askUserInfo.send_que_num || 0 }}</span>
				<span class="label">提问次数</span>
			</div>
			<div class="col item cc">
				<span class="num">{{ askUserInfo.focus_que_num || 0 }}</span>
				<span class="label">回答被关注</span>
			</div>
			<div class="col item cc">
				<span class="num">{{ askUserInfo.que_accept_scale || 0 }}%</span>
				<span class="label">答案采纳率</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	inject: ['initSuccess'],
	data() {
		return {
			question_id: '',
			detail: {},
			askUserInfo: {},
			currentuserInfo: {}
		};
	},
	computed: {
		isMyAsk() {
			return this.askUserInfo.id == this.currentuserInfo.id;
		}
	},
	async created() {
		this.question_id = this.$route.query.id;
		// 问题详情
		let detailRes = await this.$service.post('question/detail', { id: this.question_id });
		this.detail = detailRes.data;

		let askUserInfo = await this.$service.post('user_info/detail', { user_id: this.detail.user_id });
		this.askUserInfo = askUserInfo.data;
		let currentuserInfo = await this.$service.post('user_info/detail');
		this.currentuserInfo = currentuserInfo.data;

		this.initSuccess();
	},
	methods: {
		async tapSub() {
			let item = this.askUserInfo;
			if (item.is_focus === '0') {
				await this.$service.post('question/focusUser', { focus_user_id: item.id });
				item.is_focus = '1';
			} else {
				await this.$service.post('question/delFocusUser', { focus_user_id: item.id });
				item.is_focus = '0';
			}
		},
		navUserHome() {
			this.$router.push({
				path: '/user/home'
			});
		}
	}
};
</script>

<style scoped lang="less">
@import './userCard.less';
@import './common.less';
</style>
