<template>
	<div class="part-box">
		<div class="part-title">
			<div class="row title"><span class="row title-text">邀请回答</span></div>
		</div>
		<div class="list-box">
			<div class="col list">
				<template v-for="(item, index) in inviteList">
					<div class="row cb item a" @click="navUserHome(item.id)">
						<div class="row left">
							<el-image :src="item.avatar" class="avatar"></el-image>
							<div class="col user-info">
								<div class="name text-ellipsis">{{ item.nickname }}</div>
								<div class="row auth" v-if="item.is_cert == 1">
									<el-image fit="contain" :src="require('@/pc/assets/user/auth.png')" class="img"></el-image>
									<div class="status">{{ item.major_name }}</div>
								</div>
							</div>
						</div>
						<div class="row cc btn" v-if="item.is_invite == 1">已邀请</div>
						<div class="row cc btn" v-else @click.stop="confirmInvite(index)">邀ta回答</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	inject: ['initSuccess'],
	data() {
		return {
			question_id: '',
			inviteList: []
		};
	},
	async created() {
		this.question_id = this.$route.query.id;
		// 邀请列表
		let inviteList = await this.$service.post('reply/inviteList', { question_id: this.question_id, limit: 100 });
		this.inviteList = inviteList.data;
		this.initSuccess();
	},
	methods: {
		// 确认邀请
		async confirmInvite(index) {
			await this.$service.post('reply/invite', { question_id: this.question_id, invite_user: this.inviteList[index].id });
			this.$set(this.inviteList[index], 'is_invite', 1);
			this.$message({
				message: '邀请成功',
				type: 'success'
			});
		},
		navUserHome(user_id = '') {
			if (user_id) {
				this.$router.push({
					path: '/user/home',
					query: {
						user_id
					}
				});
			} else {
				this.$router.push({
					path: '/user/home'
				});
			}
		}
	}
};
</script>

<style scoped lang="less">
@import './common.less';
@import './invite.less';
</style>
