<template>
	<div>
		<div class="header"><appHeader></appHeader></div>
		<div style="height: 84px;"></div>
		<div class="row cc main-container">
			<div class="row page-main">
				<div style="width: 891px;height: 100%;">
					<keep-alive><slot /></keep-alive>
				</div>
				<div style="width: 300px;margin-left: 14px;">
					<keep-alive><appSidebarAskRight :key="$route.query.id" v-if="showRight"></appSidebarAskRight></keep-alive>
				</div>
			</div>
		</div>
		<appSideToolbar></appSideToolbar>
	</div>
</template>

<script>
import appHeader from './components/header.vue';
import appSidebar from './components/sidebar.vue';
import appSidebarAskRight from './components/sidebarAskRight.vue';
import appSideToolbar from './components/sideToolbar.vue';
export default {
	components: { appHeader, appSidebar, appSidebarAskRight, appSideToolbar },
	data() {
		return {};
	},
	computed: {
		// 防止回退时其他页面加载
		showRight() {
			return ['/ask/detail'].indexOf(this.$route.path) !== -1;
		}
	}
};
</script>
<style type="text/css">
body {
	background-color: #f6f6f6;
}
</style>
<style scoped lang="less">
.page-main {
	width: 1207px;
}
.header {
	position: fixed;
	top: 0;
	z-index: 101;
	width: 100%;
}
.main-container {
	// margin-top: 14px;
}
.sidebar-item {
	position: fixed;
	top: 84px;
	z-index: 100;
}
</style>
