<template>
	<div>
		<div v-show="showPage">
			<userCard></userCard>
			<invite></invite>
			<hot></hot>
			<list></list>
			<!-- 防止未加载完成就固定定位 -->
			<div id="ost" v-if="showPage"></div>
			<div id="st" :style="style">
				<wechat></wechat>
				<info></info>
			</div>
		</div>
		<div class="skeleton-box" v-show="!showPage">
			<template v-for="item in 3">
				<div class="skeleton-item"><el-skeleton animated :rows="7" /></div>
			</template>
		</div>
	</div>
</template>

<script>
import invite from '@/pc/components/sidebarRight/invite.vue';
import userCard from '@/pc/components/sidebarRight/userCard.vue';
import list from '@/pc/components/sidebarRight/list.vue';
import wechat from '@/pc/components/sidebarRight/wechat.vue';
import info from '@/pc/components/sidebarRight/info.vue';
import hot from '@/pc/components/sidebarRight/hot.vue';

export default {
	provide() {
		return {
			initSuccess: this.initSuccess
		};
	},
	data() {
		return {
			style: {},
			childrenCount: 6,
			initSuccessCount: 0
		};
	},
	computed: {
		showPage(e) {
			return this.initSuccessCount === this.childrenCount;
		}
	},
	components: { invite, userCard, list, wechat, info, hot },

	mounted() {
		window.addEventListener('scroll', this.handleScroll);
	},
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll, false);
	},
	methods: {
		initSuccess() {
			this.initSuccessCount++;
		},
		handleScroll() {
			if (document.getElementById('ost')) {
				const scrollTop = document.documentElement.scrollTop;
				const elOffsetTop = document.getElementById('st').offsetTop;
				const ostTop = document.getElementById('ost').offsetTop;
				if (scrollTop - ostTop + 84 < 0) {
					this.style = {};
				} else if (scrollTop - elOffsetTop + 84 >= 0) {
					this.style = {
						position: 'fixed',
						top: '84px'
					};
				} else {
					this.style = {};
				}
			} else {
				this.style = {};
			}
		}
	}
};
</script>

<style scoped lang="less">
.st {
	width: 300px;
	box-sizing: border-box;
}
.skeleton-box {
	.skeleton-item {
		background-color: #ffffff;
		padding: 12px;
		box-sizing: border-box;
		margin-bottom: 10px;
	}
}
</style>
